<template>
  <div class="container">
    <div class="user-nav">
      <el-row type="flex" justify="space-between">
        <el-col :span="8">
          <el-select
            v-model="termName"
            placeholder="学期"
            style="width: 195px"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in termlist"
              :key="index"
              :label="item.termName"
              :value="item.termName"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="16" style="text-align: right">
          <div class="userinfo">
            <p>{{ paramsData.userName }}</p>
            <span>学籍号：{{ paramsData.studentCode }}</span>
            <span>{{ paramsData.className }}</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="main">
      <div class="table-box">
        <h4>学业水平</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>项目</th>
            <th>写实记录</th>
            <th>平时成绩</th>
            <th>期中成绩</th>
            <th>期末成绩</th>
            <th>学科比赛</th>
            <th>课堂表现</th>
            <th>总计</th>
          </tr>
          <tr>
            <td>数据条数</td>
            <td>{{ academicForm.realyCount }}</td>
            <td>{{ academicForm.comExamCount }}</td>
            <td>{{ academicForm.midExamCount }}</td>
            <td>{{ academicForm.finExamCount }}</td>
            <td>{{ academicForm.subMatchCount }}</td>
            <td>{{ academicForm.showCount }}</td>
            <td>{{ academicForm.sumCount }}</td>
          </tr>
          <tr>
            <td>综素成绩（分）</td>
            <td>{{ academicForm.realyScore }}</td>
            <td>{{ academicForm.comExamScore }}</td>
            <td>{{ academicForm.midExamScore }}</td>
            <td>{{ academicForm.finExamScore }}</td>
            <td>{{ academicForm.subMatchScore }}</td>
            <td>{{ academicForm.showScore }}</td>
            <td>{{ academicForm.sumScore }}</td>
          </tr>
        </table>
      </div>
      <div class="more">
        <h4>相关记录</h4>
        <el-row
          type="flex"
          justify="space-between"
          class="more-tit"
          style="margin-bottom: "
        >
          <el-col :span="12">
            <h5>平时成绩</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <div class="more-flex" style="margin: 0">
              <span>测试次数：{{ indexResult.commonCount }}</span>
              <span>综素成绩：{{ indexResult.commonZSScore }}</span>
              <span>
                <el-button type="primary" plain @click="handleMore"
                  >查看详情</el-button
                >
              </span>
            </div>
          </el-col>
        </el-row>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>期中成绩</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <div class="more-flex">
              <span>总成绩：{{ indexResult.middleScore }}</span>
              <span>综素成绩：{{ indexResult.middleZSScore }}</span>
            </div>
          </el-col>
        </el-row>
        <el-table
          :data="midlist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column label="学科" fixed>
            <template>原始成绩</template>
          </el-table-column>
          <el-table-column v-for="(item,index) in midTitle" :key="index" :prop="item.code" :label="item.name"> </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>期末成绩</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <div class="more-flex">
              <span>总成绩：{{ indexResult.finalScore }}</span>
              <span>综素成绩：{{ indexResult.finalZSScore }}</span>
            </div>
          </el-col>
        </el-row>
        <el-table
          :data="finallist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column label="学科" fixed>
            <template>原始成绩</template>
          </el-table-column>
          <el-table-column v-for="(item,index) in finalTitle" :key="index" :prop="item.code" :label="item.name"> </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>学科比赛</h5>
          </el-col>
          <el-col :span="12" style="text-align: right"> </el-col>
        </el-row>
        <el-table
          :data="competionlist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="matchName" label="赛事名称"> </el-table-column>
          <el-table-column prop="indexRuleLevel" label="赛事级别">
          </el-table-column>
          <el-table-column prop="matchTime" label="比赛时间"> </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="handleDetailRace(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                @click="handleRemoveRace(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>课堂表现</h5>
          </el-col>
          <el-col :span="12" style="text-align: right"> </el-col>
        </el-row>
        <el-table
          :data="evaluationList"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column prop="evalItem" label="评价项目"> </el-table-column>
          <el-table-column prop="termName" label="学期" width="200">
          </el-table-column>
          <el-table-column prop="evalType" label="评价方式"> </el-table-column>
          <el-table-column prop="selfEval" label="学生自评"> </el-table-column>
          <el-table-column prop="otherEval" label="学生互评"> </el-table-column>
          <el-table-column prop="teacherEval" label="教师评价">
          </el-table-column>
          <el-table-column prop="evalStatus" label="状态"> </el-table-column>
          <el-table-column prop="evalResult" label="评价结果">
          </el-table-column>
        </el-table>
      </div>
      <div class="back">
        <el-button type="text" @click="backIndex">返回</el-button>
      </div>
    </div>

    <!-- 查看比赛详情 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" center>
      <el-form
        ref="form"
        :model="form"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item style="border-bottom: 1px dashed #e1e3e9">
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.studentCode }}</span>
        </el-form-item>
        <el-form-item label="比赛名称：">
          <span class="scontent">{{ form.matchName }}</span>
        </el-form-item>
        <el-form-item label="比赛级别：">
          <span class="scontent">{{ form.indexRuleLevel }}</span>
        </el-form-item>
		<el-form-item label="获奖等级：">
		  <span class="scontent">{{ form.resultLevel }}</span>
		</el-form-item>
        <el-form-item label="比赛时间：">
          <span class="scontent">{{ form.matchTime }}</span>
        </el-form-item>
        <el-form-item
          label="举办单位："
          style="border-bottom: 1px dashed #e1e3e9"
        >
          <span class="scontent">{{ form.awardedUnit }}</span>
        </el-form-item>
        <el-form-item label="所在学期：">
          <span class="scontent">{{ form.termName }}</span>
        </el-form-item>
        <!-- <el-form-item label="所在学校：">
          <span class="scontent">{{ form.schoolName }}</span>
        </el-form-item> -->
        <el-form-item label="佐证材料：" v-if="form._showImgList">
        	<el-image
        	  v-for="(item, index) in form._imgFileList"
        		style="width: 100px; height: 100px;"
        		:preview-src-list="form._imgFileList"		
        	  :key="index"
        	  :src="item"
        	></el-image>
        </el-form-item>
        <el-form-item label="佐证材料：" v-if="form._showOtherFileList">
        	<a
        	v-for="(item, idx) in form._otherFileList"
        	:key="item"
        	target="_blank"
        	:download="'附件' + idx"
        	:href="item">附件{{ idx + 1 }} </a>
        </el-form-item>
        <el-form-item label="备注说明：">
          <span class="scontent">{{ form.bz }}</span>
        </el-form-item>
		<el-form-item v-if="form.auditStatus == 2" label="驳回者：">
			<span class="scontent">{{ form.updateBy }}</span>
		</el-form-item>
		<el-form-item v-if="form.auditStatus == 2" label="驳回原因：">
			<span class="scontent">{{ form.causeDes }}</span>
		</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 平时成绩 -->
    <el-dialog :title="title" :visible.sync="openMore" width="75%" center>
      <el-table
        style="width: 100%"
        :data="uausllist"
        border
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column v-for="(item,index) in uauslTitle" :key="index" :prop="item.code" :label="item.name" min-width="180"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openMore = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getTerm } from "@/api/public/search";
import {
  academicByUserId,
  getProjectRaceById,
  getProjectRaceDetail,
  removeProjectRace,
  getUsualResultById,
  getMidResult,
  getFinalResult,
  getIndexResult,
  getEvaluateById,
} from "@/api/teacher/quality/Academic";
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
      termName: "",
      title: "",
      open: false,
      openMore: false,
      tableData: [],
      form: {},
      paramsData: {},
      termlist: [],
      academicForm: {},
      uausllist: [],
      uauslTitle: [],
      midlist: [],
      midTitle: [],
      finallist: [],
      finalTitle: [],
      competionlist: [],
      indexResult: {
        middleScore: 0,
        middleZSScore: 0,
        finalScore: 0,
        finalZSScore: 0,
      },
      evaluationList: [],
    };
  },
  created() {
    this.paramsData =
      this.$route.params.userName != undefined
        ? this.$route.params
        : JSON.parse(sessionStorage["params"]);
    this.termName = this.paramsData.termName;
    this.getFirst();
  },
  methods: {
    handleChange(val) {
      this.paramsData.termName = val;
      this.getFirst();
    },
    getFirst() {
      this.getTermList();
      this.getAcademic();
      this.getUauslResult()
      this.getMidResult();
      this.getFinalResult();
      this.getCopetion();
      // this.getResult();
      this.getEvaluate();
    },
    getAcademic() {
      academicByUserId({
        userId: this.paramsData.userId,
        termName: this.termName,
      }).then((res) => {
        // console.log(res);
        this.academicForm = res.data;
      });
    },
    getUauslResult() {
      getUsualResultById({
        userId: this.paramsData.userId,
        termName: this.termName,
        examType: '1'
      }).then((res) => {
        this.indexResult.commonCount = res.data.examNum
        this.indexResult.commonZSScore = res.data.indexScore
        this.uausllist = res.data.content
        this.uauslTitle = res.data.title
      });
    },
    getMidResult() {
      getMidResult({
        userId: this.paramsData.userId,
        termName: this.termName,
        examType: '2'
      }).then((res) => {
        if(res.data) {
          this.indexResult.middleScore = res.data.sumScore
          this.indexResult.middleZSScore = res.data.indexScore
          this.midlist = res.data.content
          this.midTitle = res.data.title
        }
      });
    },
    getFinalResult() {
      getFinalResult({
        userId: this.paramsData.userId,
        termName: this.termName,
        examType: '3'
      }).then((res) => {
        if(res.data) {
          this.indexResult.finalScore = res.data.sumScore
          this.indexResult.finalZSScore = res.data.indexScore
          this.finallist = res.data.content
          this.finalTitle = res.data.title
        }
      });
    },
    getCopetion() {
      getProjectRaceById({
        userId: this.paramsData.userId,
        termName: this.termName,
        pageSize: 100,
        pageNum: 1,
      }).then((res) => {
        // console.log(res);
        this.competionlist = res.data;
      });
    },
    getResult() {
      getIndexResult({
        userId: this.paramsData.userId,
        termName: this.termName,
      }).then((res) => {
        this.indexResult = res.data;
      });
    },
    getEvaluate() {
      this.evaluationList = [];
      getEvaluateById({
        userId: this.paramsData.userId,
        termName: this.termName,
      }).then((res) => {
        this.evaluationList.push(res.data);
      });
    },
    backIndex() {
      this.$router.go(-1);
    },
    handleMore() {
      this.title = "平时成绩";
      this.openMore = true;
    },
	// 处理附件
	processAth(key) {
		let temp = key.split(",")
		let imgFileList = []
		let otherFileList = []
		// 材料按文件后缀名分流
		temp.forEach(v => {
			if (this.fileNotImg(v)) {
				otherFileList.push(this.imgSrc + v)
			} else {
				imgFileList.push(this.imgSrc + v)
			}
		})
		this.form._imgFileList = imgFileList
		this.form._otherFileList = otherFileList
		this.form._showImgList = imgFileList.length > 0
		this.form._showOtherFileList = otherFileList.length > 0
	},
    handleDetailRace(row) {
      this.title = "学科比赛";
      getProjectRaceDetail({ matchId: row.id }).then((res) => {
        // console.log(res);
        this.form = res.data;
        this.open = true;
        if(this.form.proofFile) {
          this.processAth(this.form.proofFile)
        }
      });
    },
    handleRemoveRace(row) {
      this.$confirm(
        "删除该条记录后，学生对应的综素得分将被扣除，确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          removeProjectRace({ matchId: row.id }).then((res) => {
            this.$message.success("删除成功");
            this.getCopetion();
          });
        })
        .catch(() => {
          return false;
        });
    },
    getTermList() {
      getTerm({}).then((res) => {
        this.termlist = res.data;
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .main {
    .table-box {
      table {
        tr {
          th {
            width: 12.5%;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.user-nav .el-select >>> .el-input {
  margin: 10px 12px;
}
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 100%;
}
</style>